import create from "zustand";

const initial_state = {
  linkToken: null,
  linkTokenError: null,
  isLoading: false,
  itemId: null,
  accessToken: null,
  isItemAccess: null,
};

const useAppStore = create((set) => ({
  ...initial_state,
  toggleLoading: () =>
    set((state) => {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    }),
  setLinkToken: (linkToken) =>
    set((state) => {
      return {
        ...state,
        linkToken,
      };
    }),
  setLinkTokenError: (linkTokenError) =>
    set((state) => {
      return {
        ...state,
        linkTokenError,
      };
    }),
  setProducts: (products) =>
    set((state) => {
      return {
        ...state,
        products,
      };
    }),
  setItemId: (itemId) =>
    set((state) => {
      return {
        ...state,
        itemId,
      };
    }),
  setAccessToken: (accessToken) =>
    set((state) => {
      return {
        ...state,
        accessToken,
      };
    }),
  setIsItemAccess: (isItemAccess) =>
    set((state) => {
      return {
        ...state,
        isItemAccess,
      };
    }),
  clearAppState: () => set({ ...initial_state }),
}));

export default useAppStore;
