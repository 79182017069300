import React, { useCallback, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import moment from "moment";
import { Route, Routes, Navigate } from "react-router";
import axios from "axios";

import useAppStore from "../store/app.store";
import Form from "./Form";
import LaunchValidation from "./LaunchValidation";

const App = () => {
  const { isItemAccess, setLinkToken, setLinkTokenError } = useAppStore();

  const generateToken = useCallback(async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/plaid/link-token`
    );
    if (response.status !== 200) {
      setLinkToken(null);
      return;
    }
    const data = response.data;
    if (data) {
      if (data.error != null) {
        setLinkToken(null);
        setLinkTokenError(data.error);
        return;
      }
      setLinkToken(data.link_token);
      setLinkTokenError(null);
      localStorage.setItem("plaid_link_token", JSON.stringify(data));
    }
  }, [setLinkToken, setLinkTokenError]);

  useEffect(() => {
    const tokenData = localStorage.getItem("plaid_link_token");
    if (tokenData !== null && tokenData !== undefined && !!tokenData) {
      const parsedJsonData = JSON.parse(tokenData);
      if (moment(parsedJsonData.expiration).unix() > moment.utc().unix()) {
        setLinkToken(parsedJsonData.link_token);
        setLinkTokenError(null);
      } else {
        generateToken();
      }
    } else {
      generateToken();
    }
  }, [setLinkTokenError, setLinkToken, generateToken]);

  return (
    <Box display="flex" flex={1} h={"100vh"} width={"100%"} m={2}>
      <Routes>
        <Route
          path="/plaid"
          element={
            isItemAccess === null || isItemAccess === false ? (
              <LaunchValidation />
            ) : (
              <Navigate to="/form" />
            )
          }
        />
        <Route
          path="/form"
          element={
            isItemAccess && isItemAccess === true ? (
              <Form />
            ) : (
              <Navigate to="/plaid" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/form" replace />} />
      </Routes>
    </Box>
  );
};

export default App;
