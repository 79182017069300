import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/provider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";

import App from "./components/App";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://50e9c54519734c3a851a0e1f8dd757a9@o1278627.ingest.sentry.io/6478497",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ChakraProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      </ChakraProvider>
    </React.StrictMode>
  </BrowserRouter>
);
