import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  CircularProgress,
  Image,
  VStack,
} from "@chakra-ui/react";

import useAppStore from "../store/app.store";
import logo from "../images/logo.png";

const LaunchValidation = () => {
  const [isError, setIsError] = useState(false);
  const { linkToken, setItemId, setAccessToken, setIsItemAccess } =
    useAppStore();

  const onSuccess = React.useCallback(
    (public_token) => {
      const setToken = async () => {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/plaid/exhange-public-token`,
          {
            public_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          setItemId(`no item_id retrieved`);
          setAccessToken(`no access_token retrieved`);
          setIsItemAccess(false);
          setIsError(true);
          localStorage.removeItem("plaid_link_token");
          return;
        }

        const data = response.data;
        setItemId(data.item_id);
        setAccessToken(data.access_token);
        setIsError(false);
        localStorage.removeItem("plaid_link_token");
        setIsItemAccess(true);
      };
      setToken();
    },
    [setIsItemAccess, setAccessToken, setItemId]
  );

  let isOauth = false;

  const config = {
    token: linkToken,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return (
    <Box flex={1} display="flex" flexDirection="column" alignItems="center">
      {ready ? (
        open()
      ) : isError ? (
        <Center height="90vh">
          <Alert
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
            status="error"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Failed to connect with your bank account!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              We have failed to connect with your bank account, please try
              again, if problem persist please call support
            </AlertDescription>
          </Alert>
        </Center>
      ) : (
        <Center height="90vh">
          <VStack>
            <Image src={logo} size="xs" />
            <CircularProgress isIndeterminate thickness="4px" />
          </VStack>
        </Center>
      )}
    </Box>
  );
};

export default LaunchValidation;
